import { useLiveQuery } from "dexie-react-hooks";
import { v4 as uuidv4 } from 'uuid';

import { db } from "../database/oneshot";

const useProject = (id) => {
  const project = useLiveQuery(() => id ? db.projects.get(id) : undefined, [id]);

  const updateProject = (data) =>
    db.projects.put(data, id);

  const addField = (field_name) => {
    const newData = { ...project };
    newData.fields.push({
      field_id: uuidv4(),
      field_name: field_name
    });
    return updateProject(newData);
  }

  const removeField = (field_id) => {
    const newData = {...project};
    newData.fields = newData.fields.filter((item) => item.field_id !== field_id);  
      return updateProject(newData);
  }
 
  const getFields = () =>
    project?.fields || []

  return {
    project,
    addField,
    getFields,
    removeField,
    isLoaded: project !== undefined
  };
}

export default useProject;
