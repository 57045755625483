import TableSpyMainScreen from "../screens/tablespy/main";
import LoggedInWrapper from "../components/logged_in_wrapper";
import Layout from "../components/layout";

const TableSpyPage = () => {
  return (
    <LoggedInWrapper serviceName="TableSpy" serviceId="tablespy">
      <Layout serviceName="TableSpy" feedbackRedirect="https://blueprism.az1.qualtrics.com/jfe/form/SV_6lJ1Yd02eLOCthk">
        <TableSpyMainScreen />
      </Layout>
    </LoggedInWrapper>
  );
}

export default TableSpyPage;
