import styled from "styled-components";

const LayoutHeader = styled.header`
  background: ${(props) => props.theme.color.larkspur};
  padding: 0 0.8rem;
  flex: 0 0 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    color: white;
    margin: 0;
    font-size: 1.4rem;
  }
`;

export default LayoutHeader;
