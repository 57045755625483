import { useLiveQuery } from "dexie-react-hooks";
import { v4 as uuidv4 } from 'uuid';

import { db } from "../database/oneshot";

const useProjects = () => {
  const projects = useLiveQuery(() => db.projects.toArray());

  const createProject = async () =>
    db.templates.add({
      id: uuidv4(),
      fields: []
    });

  return {
    projects,
    createProject
  };
}

export default useProjects;
