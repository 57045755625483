import { useParams } from "react-router-dom";

import TemplateEditor from "../../../components/oneshot/template_editor";
import useTemplate from "../../../hooks/use_template";

const OneShotTemplatesMainScreen = () => {
  const { id } = useParams();
  const { template, addAnnotation, updateAnnotation, removeAnnotation } = useTemplate(id);

  if (template) {
    return <TemplateEditor
      template={template}
      addAnnotation={addAnnotation}
      updateAnnotation={updateAnnotation}
      removeAnnotation={removeAnnotation} />
  };

  return null;
}

export default OneShotTemplatesMainScreen;
