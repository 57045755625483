import { useMemo } from "react";
import styled from "styled-components";
import FormField from "@blueprism/ui-core/components/FormField";
import InputField from "@blueprism/ui-core/components/InputField";
import Row from "@blueprism/ui-core/layout/Row";

const StyledAnnotation = styled(Row)`
  margin-bottom: 0.8rem;
`;

const AnnotationInputWrap = styled.div`
  flex: 3;
  margin: 0 0.4rem;
`;

const AnnotationSelectWrap = styled.div`
  flex: 1;
  margin: 0 0.4rem;
`;

const AnnotationField = styled.div`
  background: ${(props) => props.theme.color.highlight};
  display: flex;
  p {
    flex: 1;
  }
`;

const Annotation = ({
  annotation,
  fields
}) => {
  const fieldName = useMemo(() => {
    return fields.find((field) => field.field_id === annotation.field_id)?.field_name || "";
  }, [fields, annotation.field_id]);

  return (
    <StyledAnnotation>
      <AnnotationInputWrap>
        <FormField
          htmlFor={`annotation_${annotation.annotation_id}_field`}
          label="Field">
          <AnnotationField>
          <InputField
            id={`annotation_${annotation.annotation_id}_field`}
            name={`annotation_${annotation.annotation_id}_field`}
            value={fieldName}
            disabled />
          </AnnotationField>
        </FormField>
      </AnnotationInputWrap>
      <AnnotationInputWrap>
        <FormField
          htmlFor={`annotation_${annotation.annotation_id}_bounded_text`}
          label="Text">
          <InputField
            id="bounded_text"
            name="bounded_text"
            value={annotation.bounded_text}
            disabled />
        </FormField>
      </AnnotationInputWrap>
      <AnnotationSelectWrap>
        <FormField
          htmlFor={`annotation_${annotation.annotation_id}_row_number`}
          label="Row">
          <InputField
            id="row_number"
            name="row_number"
            value={annotation.row_number === -1 ? '' : annotation.row_number}
            disabled />
        </FormField>
      </AnnotationSelectWrap>
    </StyledAnnotation>
  );
}

export default Annotation;
