import { postRequest } from "./base";

export const onExtract = (image, authKey) =>
  postRequest({
    url: `${process.env.REACT_APP_API_BASE_URL}/tablespy/extract`,
    authKey: authKey,
    data: {
      key: authKey,
      payload: {
        image: image
      }
    },
    skipParse: true
  })
