import { Box, Button, Menu, MenuItem } from "@blueprism/ui-core";
import { exportDB } from "dexie-export-import";
import { useRef, useState } from "react";
import styled from "styled-components";
import { db } from "../../../database/oneshot";
import ImportModal from "../import_modal";

const SettingsButton = styled(Button)`
  color: #fff;
  background: transparent;
  margin: 0;
`;

const OneShotSettingsDropdown = () => {
  const [showingImportModal, setShowingImportModal] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const buttonRef = useRef(null);

  function handleMenuVisibility() {
    setMenuVisible((prevMenuVisible) => !prevMenuVisible);
  }

  function handleClose() {
    setMenuVisible(false);
  }


  const onExportClick = (async () => {
    const exportedDb = await exportDB(db);

    const url = window.URL.createObjectURL(
      new Blob([exportedDb]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `oneshot_bu_${new Date().getTime()}.json`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  });

  const onResetClick = () => {
    db.delete();
    window.location.reload(false);
  }

  return (
    <Box>
      <ImportModal visible={showingImportModal} onClose={() => setShowingImportModal(false)} />
      <SettingsButton onClick={handleMenuVisibility} ref={buttonRef} aria-haspopup="true">
        Settings
      </SettingsButton>
      <Menu visible={menuVisible} anchorRef={buttonRef} onClose={handleClose}>
        <MenuItem onClick={onExportClick}>Export Project</MenuItem>
        <MenuItem onClick={() => setShowingImportModal(true)}>Import Project</MenuItem>
        <MenuItem onClick={onResetClick}>Reset</MenuItem>
      </Menu>
    </Box>
  )
}

export default OneShotSettingsDropdown;
