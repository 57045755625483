import { useState } from "react";
import styled from "styled-components";
import Button from "@blueprism/ui-core/components/Button";
import { BoundingBoxAdd } from "@blueprism/ui-icons";
import Annotation from "./annotation";
import FieldsModal from "./fields_modal";
import useProject from "../../../../hooks/use_project";

const AnnotationsWrap = styled.div`
  display: sticky;
`;

const AddNewAnnotationButton = styled(Button)`
  margin-top: 1rem;
`;

const TemplateAnnotations = ({
    annotations = [],
    addNewAnnotation,
    startEditingAnnotation,
    handleAnnotationChange,
    onRemoveAnnotation,
    editingIndex,
    projectId
}) => {
    const { isLoaded, getFields, addField, removeField } = useProject(projectId);
    const fields = getFields();

    const [showingFieldModal, setShowingFieldModal] = useState(undefined);
    const handleClose = () => setShowingFieldModal(undefined);

    const handleFieldChange = (fieldId) => {
        handleAnnotationChange(showingFieldModal, "field_id", fieldId);
    };

    const handleRowChange = (index, row) =>
        handleAnnotationChange(index, "row_number", parseInt(row));

    return (
        <AnnotationsWrap>
            {annotations.map((annotation, index) =>
                <Annotation
                    key={`annotation_${annotation.annotation_id}`}
                    annotation={annotation}
                    onEditClick={startEditingAnnotation}
                    onFieldEditClick={() => setShowingFieldModal(index)}
                    onRowChange={(e) => handleRowChange(index, e.target.value)}
                    onRemove={onRemoveAnnotation}
                    index={index}
                    beingEdited={editingIndex === index}
                    fields={fields} />
            )}
            <AddNewAnnotationButton onClick={addNewAnnotation} iconLeft={<BoundingBoxAdd />}>
                Add new
            </AddNewAnnotationButton>
            <FieldsModal
                selectedFieldId={annotations[showingFieldModal]?.field_id}
                visible={showingFieldModal !== undefined}
                onClose={handleClose}
                onFieldChange={handleFieldChange}
                fields={fields}
                addField={addField}
                removeField={removeField}
                annotations={annotations}
                fieldsLoaded={isLoaded} />
        </AnnotationsWrap>
    )
}

export default TemplateAnnotations;
