import { useState } from 'react';

import { FilePicker } from "@blueprism/ui-pattern-file-picker";

import styled from "styled-components";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Button from '@blueprism/ui-core/components/Button';
import { FormField, Row } from '@blueprism/ui-core';
import { onExtract } from "../services/api/tablespy";
import { fileToDataUrl } from "../services/file_helpers";
import RenderImage from "./render_image"
import DrawLayout from './draw_layout';

const ImageUploaderWrapper = styled.div`
  padding: 0 0.6rem;
  width: 100%;
`;

const ImagePlaceholder = styled.label`
  height: 7rem;
  width: 100%;
  border-radius: 0.8rem;
  margin: 0.8rem 0;
  background: ${(props) => props.theme.color.highlight};
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 1.2rem;
  }
`

const ImageWrap = styled.div`
  position: relative;
  background: ${(props) => props.theme.color.paper};
  padding: 0.8remp
  border-radius: 0.8rem;
`;

const ImageUploader = ({ 
  authKey,
  imageData,
  setImageData,
  imageLayout,
  setImageLayout,
  isProcessing,
  setIsProcessing,
  showBoundingBoxes,
  setShowErrorModal,
  setShowBoundingBoxes,
  handleChange,
  handleOnClear,
  imageName,
  file
}) => {
  const [errors, setErrors] = useState([]);
  const [imageScale, setImageScale] = useState(0);

  const checkFileSize = (file) => {
    if (file.size > 15050000) {
      setErrors([...errors, 'File must be 15MB or less. Choose a smaller file']);
    } else {
      setErrors([]);
      handleChange(file)
    }
  }

  const onChange = (e) => {
    const fileToCheck = e.target.files[0];

    checkFileSize(fileToCheck)
  }

  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const onDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();

    let file = undefined;

    if (e.dataTransfer.items) {
      if (e.dataTransfer.items[0].kind === "file") {
        file = e.dataTransfer.items[0].getAsFile();
      }
    } else if (e.dataTransfer.files) {
      file = e.dataTransfer.files[0];
    }

    if (file) {
      checkFileSize(file);
    }
  }

  const onImageExtract = () => {
    setIsProcessing(true);
    fileToDataUrl(file)
      .then((dataUrlImage) => {
        onExtract(dataUrlImage, authKey)
        .then(
          (res) => res.json(),
          (err) => setShowErrorModal(true)
        )
        .then(async (res) => {
          if (res.data === null) {
            setShowErrorModal(true);
          } else {
            setImageData(await res.data.data);
            setImageLayout(await res.data.layout);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setIsProcessing(false));
      })
      .catch((err) => console.error(err))
  }

  const toCSV = (arr, delimiter = ';') => [
    ...arr.map(row => Object.values(row).join(delimiter))
  ].join('\n');

  const updateExtension = (filename, extension='.csv') => {
    let pos = filename.lastIndexOf('.');
    filename = filename.substr(0, pos < 0 ? filename.length : pos) + extension;
    return filename
  }

  const onDownload = () => {
    const csvString = `data:text/csv;chatset=utf-8,${encodeURIComponent(
      toCSV(imageData)
    )}`;
    const link = document.createElement('a');
    link.href = csvString;
    link.download = updateExtension(imageName);
    link.click();
  }

  const onVisualiseImage = () => {
    setShowBoundingBoxes(!showBoundingBoxes);
  }


  return (
    <ImageUploaderWrapper>
      <FormField
        error={errors.length > 0}
        errorText={errors}
        htmlFor="FilePickerWithErrorHandling">
        <Row justify="right" gap="base">
          <FilePicker
            accept=".png, .PNG, .jpg, .JPG, .jpeg, .JPEG"
            files={file ? [file] : null}
            id="ImagePicker"
            chooseButtonLabel="Upload Image"
            clearButtonLabel="Clear"
            onChange={onChange}
            onClear={handleOnClear}
          />
          <Button
            disabled={isProcessing || !file}
            onClick={onImageExtract}
          >
            Extract Data
          </Button>
          <Button
            disabled={!file || !imageData}
            onClick={onDownload}
          >
            Download Data
          </Button>
          <Button
            disabled={!file || !imageData}
            onClick={onVisualiseImage}
          >
            {showBoundingBoxes ? "Hide Layout" : "Visualise Layout"}
          </Button>
        </Row>
      </FormField>

      <ImageWrap>
        { !file &&
          <ImagePlaceholder
            htmlFor="ImagePicker"
            onDrop={onDrop}
            onDragOver={onDragOver}>
            <p>Click or drag an image here</p>
          </ImagePlaceholder>}

        { file && 
          <RenderImage
            file={file}
            setImageScale={setImageScale}
          /> 
        }

        { imageLayout && showBoundingBoxes &&
          <DrawLayout
            imageScale={imageScale}
            imageLayout={imageLayout}
          />
        }
      </ImageWrap>

    </ImageUploaderWrapper>
  );
}

export default ImageUploader;
