import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import ThemeProvider, { themes } from '@blueprism/ui-core/theme/ThemeProvider';

import './App.css';
import DocComparePage from "./pages/doccompare";
import TableSpyPage from "./pages/tablespy";
import OneShotIndexPage from "./pages/oneshot";
import OneShotTemplatesEditPage from "./pages/oneshot/templates/edit";
import OneShotExtractsShowPage from "./pages/oneshot/extracts/show";

function App() {
  return (
    <ThemeProvider theme={themes.LIGHT}>
      <BrowserRouter>
        <Routes>
          <Route path="doccompare" element={<DocComparePage />} />
          <Route path="tablespy" element={<TableSpyPage />} />

          <Route path="oneshot/extracts/:id" element={<OneShotExtractsShowPage />} />
          <Route path="oneshot/templates/:id/edit" element={<OneShotTemplatesEditPage />} />
          <Route path="oneshot" element={<OneShotIndexPage />} />

          <Route path="*" element={<p>Not found</p>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
