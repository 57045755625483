import { Box, Button, Cluster } from "@blueprism/ui-core";
import { useState } from "react";
import NewTemplateModal from "../../../components/oneshot/new_template_modal";
import ExtractModal from "../../../components/oneshot/new_extract_modal";
import TemplatesTable from "../../../components/oneshot/templates_table";
import ExtractsTable from "../../../components/oneshot/extracts_table";
import useTemplates from "../../../hooks/use_templates";
import useExtracts from "../../../hooks/use_extracts";

const OneShotTemplatesIndexScreen = () => {
  const { extracts, deleteExtract } = useExtracts();
  const { templates, createTemplate, deleteTemplate } = useTemplates();
  const [showingExtractModal, setShowingExtractModal] = useState(false);
  const [showingTemplateModal, setShowingTemplateModal] = useState(false);

  return (
    <>
      <NewTemplateModal visible={showingTemplateModal} onClose={() => setShowingTemplateModal(false)} />
      <ExtractModal visible={showingExtractModal} onClose={() => setShowingExtractModal(false)} />
      <Cluster justify="between" align="center" padding="small">
        <h1>Templates</h1>
        <Box padding="small">
          {/* <Button onClick={() => setShowingExtractModal(true)}>Test file</Button> */}
          <Button onClick={() => setShowingTemplateModal(true)}>Add template</Button>
        </Box>
      </Cluster>
      <TemplatesTable templates={templates} deleteTemplate={deleteTemplate} />

      <Cluster justify="between" align="center" padding="small">
        <h1>Extracts</h1>
        <Box padding="small">
          <Button onClick={() => setShowingExtractModal(true)}>Test file</Button>
        </Box>
      </Cluster>
      <ExtractsTable extracts={extracts} createTemplate={createTemplate} deleteExtract={deleteExtract} />
    </>
  );
}

export default OneShotTemplatesIndexScreen;
