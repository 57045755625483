export const fileToDataUrl = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    
    fileReader.onload = () => resolve(fileReader.result.split(',', 2)[1]);
    fileReader.onerror = () => reject("There was an error");
  });
}

export const b64toBlob = (base64, type = 'application/pdf') => 
  fetch(`data:${type};base64,${base64}`).then(res => res.blob())
