import useExtract from "../../../hooks/use_extract";
import { Button, ButtonGroup, Row } from "@blueprism/ui-core";
import { TriangleBack } from "@blueprism/ui-icons";
import { useRef, useState } from "react";
import styled from "styled-components";
import EditableTemplate from "../template_editor/editable_template";
import useProjects from "../../../hooks/use_projects";
import ExtractAnnotations from "../extract_annotations";
import { useNavigate } from "react-router-dom";

const EditorSection = styled.div`
  background: ${(props) => props.theme.color.highlight};
  flex: 1;
  padding: 0.8rem;
  overflow: scroll;
`;

const EditorSidebar = styled.div`
  flex: 0 0 400px;
  padding: 0.8rem 0.4rem;
  overflow: scroll;
`;

const StyledButton = styled(Button)`
  white-space: normal;
`;

const ExtractViewer = ({ id }) => {
  const navigate = useNavigate();
  const imageElementRef = useRef();
  const { projects } = useProjects();
  const { extract, isLoaded } = useExtract(id);
  const [imageScale, setImageScale] = useState(0);
  const [showBoundingBoxes, setShowBoundingBoxes] = useState(false);
  const [showAnnotationBoxes, setShowAnnotationBoxes] = useState(false);

  const onDownload = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(extract.data.extractions[0].annotations, null, 4)
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    // link.download = updateExtension(imageName);
    link.download = 'data.json' // TODO: rename according to file name
    link.click();
  }

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      <EditorSection>
        <Row justify="between">
          <Button
            onClick={() => navigate("/oneshot")}
            iconLeft={<TriangleBack />}>
            Back to menu
          </Button>
          <ButtonGroup>
            <StyledButton 
              onClick={() => setShowAnnotationBoxes(!showAnnotationBoxes)}
              disabled={!extract.data.extractions[0].annotations}>
                Annotations
            </StyledButton>
            <StyledButton onClick={() => setShowBoundingBoxes(!showBoundingBoxes)}>Visualise OCR</StyledButton>
            <StyledButton onClick={() => onDownload()}>Download</StyledButton>
          </ButtonGroup>
        </Row>
        <EditableTemplate
          displayImage={extract.data.extractions[0].document.image}
          words={extract.data.extractions[0].document.words}
          showBoundingBoxes={showBoundingBoxes}
          annotations={extract.data.extractions[0].annotations}
          showAnnotationBoxes={showAnnotationBoxes}
          imageElementRef={imageElementRef}
          imageScale={imageScale}
          setImageScale={setImageScale}
          />
      </EditorSection>
      <EditorSidebar>
        <ExtractAnnotations
          annotations={extract.data.extractions[0].annotations}
          projectId={projects[0].id}
          />
      </EditorSidebar>
    </>
  )
}

export default ExtractViewer;
