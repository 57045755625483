import OneShotMainScreen from "../../../screens/oneshot/templates/main";
import LoggedInWrapper from "../../../components/logged_in_wrapper";
import LayoutOneShot from "../../../components/layout/oneshot";

const OneShotTemplatesEditPage = () => {
  return (
    <LoggedInWrapper serviceName="OneShot" serviceId="oneshot">
      <LayoutOneShot>
        <OneShotMainScreen />
      </LayoutOneShot>
    </LoggedInWrapper>
  );
}

export default OneShotTemplatesEditPage;
