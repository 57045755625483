import { useState } from "react";

import useAuthKeyStore from "../../stores/auth_key";
import ImageUploader from "../../components/image_uploader";
import ProcessingWidget from "../../components/processing_widget";
import { DialogModal } from "@blueprism/ui-core";

const TableSpyMainScreen = () => {
  const { authKey } = useAuthKeyStore();
  const [imageRaw, setRawImage] = useState(undefined);
  const [imageName, setImageName] = useState(undefined);
  const [imageData, setImageData] = useState(undefined);
  const [isProcessing, setIsProcessing] = useState(false);
  const [imageLayout, setImageLayout] = useState(undefined);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showBoundingBoxes, setShowBoundingBoxes] = useState(true);

  const handleOnClear = () => {
    setRawImage(undefined);
    setImageName(undefined);
    setImageData(undefined);
    setImageLayout(undefined);
    setShowBoundingBoxes(true);
  }

  const handleOnChange = (file) => {
    setRawImage(file);
    setImageName(file.name);
  }

  return (
    <>
      <ImageUploader
        file={imageRaw}
        handleOnClear={() => handleOnClear(setRawImage)}
        handleChange={(file) => handleOnChange(file)}
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
        authKey={authKey}
        imageName={imageName}
        imageData={imageData}
        setImageData={setImageData}
        imageLayout={imageLayout}
        setImageLayout={setImageLayout}
        setShowErrorModal={setShowErrorModal}
        showBoundingBoxes={showBoundingBoxes}
        setShowBoundingBoxes={setShowBoundingBoxes} />

      <DialogModal
        closeButton
        overlay
        visible={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        width="600px">
          <p>Unable to extract data from the given image. Please upload a different image and try again.</p>
      </DialogModal>

      { isProcessing && <ProcessingWidget /> }
    </>
  );
}

export default TableSpyMainScreen;
