import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../database/oneshot";

const useTemplate = (id) => {
  const template = useLiveQuery(() => db.templates.get(id), [id]);

  const updateTemplate = (data) =>
    db.templates.put(data, id);

  const addAnnotation = (annotation) => {
    const newData = {...template};
    if (!newData.annotations) {
      newData.annotations = [];
    }
    newData.annotations.push(annotation);
    return updateTemplate(newData);
  };

  const updateAnnotation = (annotation, index) => {
    const newData = {...template};
    newData.annotations[index] = annotation;
    return updateTemplate(newData);
  }

  const removeAnnotation = (index) => {
    const newData = {...template};
    newData.annotations.splice(index, 1);
    return updateTemplate(newData);
  }

  return {
    template,
    updateTemplate,
    addAnnotation,
    updateAnnotation,
    removeAnnotation
  };
}

export default useTemplate;
