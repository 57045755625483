import { Button, Row } from "@blueprism/ui-core";
import DialogModal from "@blueprism/ui-core/components/DialogModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useExtracts from "../../../hooks/use_extracts";
import useProjects from "../../../hooks/use_projects";
import useTemplates from "../../../hooks/use_templates";
import { docProcess, postExtract } from "../../../services/api/oneshot";
import { fileToDataUrl } from "../../../services/file_helpers";
import useAuthKeyStore from "../../../stores/auth_key";
import OneShotPdfUploader from "../../oneshot_pdf_uploader";
import ProcessingOverlay from "../processing_overlay";

const FooterContent = ({ onClose, onSubmit }) => (
  <Row justify="between" padding="small">
    <Button onClick={onClose}>Cancel</Button>
    <Button onClick={onSubmit}>Process</Button>
  </Row>
);

const transformDataForExtract = async (project, templates, documents) => {
  const fields = project.fields.map((field) => {
    field.data_type = "Unknown";

    return field;
  });

  const template_fields = templates.reduce((acc, template) => {
    return template.annotations ? [
      ...acc,
      ...template.annotations.map((annotation) => ({
        field_id: annotation.field_id,
        doc_id: annotation.doc_id,
        is_word_unique: false,
        is_block_unique: false
      }))]
    : acc;
  }, []);
  const annotations = templates.reduce((acc, template) => {
    return template.annotations ? [...acc, ...template.annotations] : acc;
  }, []);
  const updatedTemplates = templates.map(({ words, doc_id, image, page_size }) => {
    return {
      words,
      doc_id,
      page_size,
      image
    }
  });

  return {
    fields,
    template_fields,
    annotations,
    template_documents: updatedTemplates,
    new_documents: documents
  }
}

const ExtractModal = ({
  visible,
  onClose
}) => {
  const navigate = useNavigate();
  const { projects } = useProjects();
  const { createExtract } = useExtracts();
  const project = projects ? projects[0] : undefined;
  const { templates } = useTemplates();

  const [file, setFile] = useState(undefined);
  const { authKey } = useAuthKeyStore();
  const [isProcessing, setIsProcessing] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [useTextract, setUseTextract] = useState(false);

  const toggleUseTextract = () => {
    setUseTextract(current => !current)
  }

  const handlePdfChange = (newFile) => {
    setFile(newFile);
  }

  const handlePdfClear = () => {
    setFile(undefined);
  }

  const handleModalSubmit = async () => {
    setIsProcessing(true);

    fileToDataUrl(file)
    .then((processedField) => docProcess(0, processedField, pageNum, authKey, useTextract))
    .then((res) => res.json())
    .then((documents) => transformDataForExtract(project, templates, documents.data))
    .then((data) => postExtract(data, authKey))
    .then((res) => res.json())
    .then((res) => {
      if (res.data) {
        console.log('res', res);
        return createExtract(res);
      } else {
        throw new Error();
      }
    })
    .then((id) => navigate(`/oneshot/extracts/${id}`))
    .catch((err) => setShowErrorModal(true))
    .finally(() => setIsProcessing(false));
  }

  return (
    <>
      <DialogModal
        closeButton
        overlay
        visible={visible}
        onClose={onClose}
        headerContent={<h3 style={{ marginBottom: "0px" }}>Extract</h3>}
        footerContent={<FooterContent onClose={onClose} onSubmit={handleModalSubmit} />}
        width="600px"
        >
        <p>Upload your PDF file here, and then click 'Process' to begin text extraction of the document.</p>
        <OneShotPdfUploader
          toggleUseTextract={toggleUseTextract}
          handleChange={handlePdfChange}
          handleOnClear={handlePdfClear}
          setPageNum={setPageNum}
          pageNum={pageNum}
          file={file}
          name="pdf_input_extract"
          />
        { isProcessing && <ProcessingOverlay><p>Processing</p></ProcessingOverlay> }
      </DialogModal>

      <DialogModal
        closeButton
        overlay
        visible={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        width="600px">
          <p>Unable to extract data from the given PDF. Please upload a different PDF or try 
              the <b>Textract</b> option.</p>
      </DialogModal>
    </>
  )
}

export default ExtractModal;
