import Dexie from "dexie";
import { v4 as uuidv4 } from "uuid";
// import template from "../data/doc_process_sample.json";

export const db = new Dexie("oneShotDatabase");
db.version(1).stores({
  projects: "id",
  templates: "id, project_id",
  extracts: "id, template_id"
});

db.on("populate", async (transaction) => {
  const projectId = uuidv4();

  await transaction.projects.add({ id: projectId, fields: [] });
  // await transaction.templates.add({
  //   id: uuidv4(),
  //   project_id: projectId,
  //   processed: true,
  //   ...template.output_json.data["5"]
  // });
});

db.open();
