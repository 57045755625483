import { Button, Row } from "@blueprism/ui-core";
import DialogModal from "@blueprism/ui-core/components/DialogModal";
import { FilePicker } from "@blueprism/ui-pattern-file-picker";
import { importInto } from "dexie-export-import";
import { useState } from "react";
import { db } from "../../../database/oneshot";
import ProcessingOverlay from "../processing_overlay";

const FooterContent = ({ onClose, onSubmit }) => (
  <Row justify="between" padding="small">
    <Button onClick={onClose}>Cancel</Button>
    <Button onClick={onSubmit}>Import Project</Button>
  </Row>
);

const ImportModal = ({
  visible,
  onClose
}) => {
  const [file, setFile] = useState(undefined);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleFileChange = ({ target: { files: [uploadedfile]} }) => {
    setFile(uploadedfile);
  }

  const handleFileClear = () => {
    setFile(undefined);
  }

  const handleModalSubmit = () => {    
    setIsProcessing(true);
    console.log("file", file);
    new Promise(async (resolve) => {
      db.projects.clear()
        .then(() => db.templates.clear())
        .then(() => db.extracts.clear())
        .then(() => importInto(db, file))
        .then(() => resolve())
        .finally(() => {
          setIsProcessing(false);
          onClose();
        });
    });
  }

  return (
    <DialogModal
      closeButton
      overlay
      visible={visible}
      onClose={onClose}
      headerContent={<h3 style={{ marginBottom: "0px" }}>New Template</h3>}
      footerContent={<FooterContent onClose={onClose} onSubmit={handleModalSubmit} />}
      width="600px"
      >
      <p>Upload a OneShot backup .json file here.</p>
      <FilePicker
        accept=".json, .JSON"
        files={file ? [file] : null}
        chooseButtonLabel="Upload Backup"
        clearButtonLabel="Clear"
        onChange={handleFileChange}
        onClear={handleFileClear}
      />
      { isProcessing && <ProcessingOverlay><p>Processing</p></ProcessingOverlay> }
    </DialogModal>
  )
}

export default ImportModal;
