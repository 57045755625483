import styled from "styled-components";
import Button from "@blueprism/ui-core/components/Button/Button";
import FormField from "@blueprism/ui-core/components/FormField";
import InputField from "@blueprism/ui-core/components/InputField/InputField";
import { useEffect, useState } from "react";
import useLocalStorage from "../hooks/use_local_storage";
import { checkAuthKey } from "../services/api/login";
import useAuthKeyStore from "../stores/auth_key";

const LoginWrap = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginScreen = ({ serviceId, serviceName }) => {
  const [error, setError] = useState(undefined);
  const [autoLoginAttempted, setAutoLoginAttempted] = useState(false);
  const { getValue, setValue } = useLocalStorage(serviceId);
  const { authKey, setAuthKey, setLoggedIn } = useAuthKeyStore();

  const onChange = (e) => {
    setAuthKey(e.target.value);
  }

  const performAuthKeyCheck = () => {
    return checkAuthKey(authKey, serviceId).then((res) => {
      if (res.status === 200) {
        setLoggedIn(true);
        setValue(authKey);
      } else {
        throw Error;
      }
    }).catch((err) => {
      console.error(err);
      setError("Unable to login, check your auth key and try again.");
      setValue("");
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    performAuthKeyCheck();
  }

  useEffect(() => {
    setAuthKey(getValue());
  }, []);

  useEffect(() => {
    if(!autoLoginAttempted && authKey) {
      performAuthKeyCheck().then(() => {
        setAutoLoginAttempted(true);
      });
    } else {
      setAutoLoginAttempted(true);
    }
  }, [authKey]);

  if (!autoLoginAttempted) {
    return <p>Loading</p>
  }

  return (
    <LoginWrap>
      <form onSubmit={onSubmit}>
        <h1>Login to {serviceName}</h1>
        <FormField
          htmlFor="auth_key_input"
          label="Auth key"
          error={error}
          errorText={error}>
          <InputField id="auth_key_input" value={authKey} onChange={onChange} />
        </FormField>
        <Button type="submit">Login</Button>
      </form>
    </LoginWrap>
  );
}

export default LoginScreen;
