import DocCompareMainScreen from "../screens/doccompare/main";
import LoggedInWrapper from "../components/logged_in_wrapper";
import Layout from "../components/layout";

const DocComparePage = () => {
  return (
    <LoggedInWrapper serviceName="DocCompare" serviceId="doccompare">
      <Layout serviceName="DocCompare" feedbackRedirect="https://blueprism.az1.qualtrics.com/jfe/form/SV_daGUWOu3TtZWjX0">
        <DocCompareMainScreen />
      </Layout>
    </LoggedInWrapper>
  );
}

export default DocComparePage;
