const useLocalStorage = (key) => {
  const getValue = () => {
    return window.localStorage.getItem(key)
  }

  const setValue = (value) => {
    window.localStorage.setItem(key, value);
  }

  return {
    getValue, setValue
  }
}

export default useLocalStorage;