import { useState, useEffect, useRef } from 'react';
import { b64toBlob, fileToDataUrl } from "../services/file_helpers";
import styled from "styled-components";

const ImagePreview = styled.img`
  width: 100%;
`;

const RenderImage = ({ file, setImageScale }) => {
  const imageElementRef = useRef();
  const [image, setImage] = useState(undefined);
  const [imageOriginalDimensions, setImageOriginalDimensions] = useState({});
  const [imageRenderedDimensions, setImageRenderedDimensions] = useState({});

  useEffect(() => {
    fileToDataUrl(file)
      .then((imageData) => {
        b64toBlob(imageData, "application/png")
          .then((parsedImage) => {
            // An image object allows us to extract details about the image
            const imageObject = new Image();
            imageObject.onload = function() {
              setImageOriginalDimensions({
                width: this.width,
                height: this.height
              });
              setImageRenderedDimensions({
                width: imageElementRef.current.clientWidth,
                height: imageElementRef.current.clientHeight
              });

              setImageScale(imageElementRef.current.clientWidth / this.width);
            }
            imageObject.src = URL.createObjectURL(parsedImage)
            setImage(imageObject)
          }); // TODO: catch
      }); // TODO: catch
  }, []);

  useEffect(() => {
    if (imageElementRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        setImageRenderedDimensions({
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height
        });
        setImageScale(entries[0].contentRect.width / imageOriginalDimensions.width);
      });

      resizeObserver.observe(imageElementRef.current);
    }
  }, [imageOriginalDimensions.width]);

  return (
    <>
      { image && <ImagePreview ref={imageElementRef} src={image.src} />}
    </>
  )
}

export default RenderImage;
