import { useLiveQuery } from "dexie-react-hooks";
// import { v4 as uuidv4 } from 'uuid';

import { db } from "../database/oneshot";

const useExtract = (id) => {
  const extract = useLiveQuery(() => id ? db.extracts.get(id) : undefined, [id]);

  return {
    extract,
    isLoaded: extract !== undefined
  };
}

export default useExtract;
