import { useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import styled from "styled-components";
import EditableTemplate from "./editable_template";
import TemplateAnnotations from "./template_annotations";
import { Button, ButtonGroup, Row } from "@blueprism/ui-core";
import { useNavigate } from "react-router-dom";
import { TriangleBack } from "@blueprism/ui-icons";

const EditorSection = styled.div`
  background: ${(props) => props.theme.color.highlight};
  flex: 1;
  padding: 0.8rem;
  overflow: scroll;
`;

const EditorSidebar = styled.div`
  flex: 0 0 500px;
  padding: 0.8rem 0.4rem;
  overflow: scroll;
`;

const StyledButton = styled(Button)`
  white-space: normal;
`;

// We're passing in image scale here, as the words bounding box is in the 1:1 scale, but the box is defined by imageScale
const calculateWordsInBox = (words, box, imageScale) => {
  const scaledBox = {
    x: box.x / imageScale,
    y: box.y / imageScale,
    width: box.width / imageScale,
    height: box.height / imageScale
  };

  const highlightedWords = words.filter((word) => {
    if (
      scaledBox.x < word.bbox.xmin + word.bbox.width &&
      scaledBox.x + scaledBox.width > word.bbox.xmin &&
      scaledBox.y < word.bbox.ymin + word.bbox.height &&
      scaledBox.height + scaledBox.y > word.bbox.ymin
    ) {
      return word;
    }

    return false;
  });

  return highlightedWords;
}

const TemplateEditor = ({ template, addAnnotation, updateAnnotation, removeAnnotation }) => {
  const navigate = useNavigate();
  const imageElementRef = useRef();
  const [dragStartPosition, setDragStartPositions] = useState({});
  const [selectionBoxProperties, setSelectionBoxProperties] = useState({});
  const [imageScale, setImageScale] = useState(0);
  const [showBoundingBoxes, setShowBoundingBoxes] = useState(false);
  const [showAnnotationBoxes, setShowAnnotationBoxes] = useState(false);

  const annotations = template.annotations;
  const [editingAnnotationIndex, setEditingAnnotiationIndex] = useState(undefined);
  const [highlightedWords, setHighlightedWords] = useState([]);

  const handleDragStart = (e) => {
    if (editingAnnotationIndex !== undefined) {
      const box = imageElementRef.current.getBoundingClientRect();
      setDragStartPositions({ x: e.clientX - box.left, y: e.clientY - box.top })
    }
  }

  const handleDragMove = (e) => {
    if(editingAnnotationIndex !== undefined) {
      const box = imageElementRef.current.getBoundingClientRect();
      const newCurrentPosition = { x: e.clientX - box.left, y: e.clientY - box.top };
      const newSelectionBoxProperties = {
        width: Math.abs(newCurrentPosition.x - dragStartPosition.x),
        height: Math.abs(newCurrentPosition.y - dragStartPosition.y),
        x: dragStartPosition.x + (newCurrentPosition.x < dragStartPosition.x ? newCurrentPosition.x - dragStartPosition.x : 0),
        y: dragStartPosition.y + (newCurrentPosition.y < dragStartPosition.y ? newCurrentPosition.y - dragStartPosition.y : 0),
      }
      setSelectionBoxProperties(newSelectionBoxProperties);
      const wordsHighlighted = calculateWordsInBox(template.words, newSelectionBoxProperties, imageScale);
      setHighlightedWords(wordsHighlighted);
    }
  }

  const handleDragEnd = (e) => {
    if (editingAnnotationIndex !== undefined) {
      const updatedAnnotation = annotations[editingAnnotationIndex];
      updatedAnnotation.bbox = {
        xmin: selectionBoxProperties.x / imageScale,
        ymin: selectionBoxProperties.y / imageScale,
        width: selectionBoxProperties.width / imageScale,
        height: selectionBoxProperties.height / imageScale
      }
      updatedAnnotation.bounded_text = highlightedWords.map((word) => word.text).join(" ");
      updateAnnotation(updatedAnnotation, editingAnnotationIndex);
      setEditingAnnotiationIndex(undefined);
      setDragStartPositions({});
      setSelectionBoxProperties({ width: 0, height: 0, x: 0, y: 0 });
      setHighlightedWords([]);
    }
  }

  const addNewAnnotation = () => {
    const newAnnotation = {
      annotation_id: uuidv4(),
      doc_id: template.doc_id,
      field_id: "",
      bounded_text: "",
      bbox:{
         xmin: 0,
         ymin: 0, 
         width: 0,
         height: 0,
      },
      row_number: -1,
      in_table: false
    };
    addAnnotation(newAnnotation).then((res) => console.log("res", res)).catch((err) => console.log("err", err))
  }

  const startEditingAnnotation = (index) => {
    if (editingAnnotationIndex !== index) {
      setEditingAnnotiationIndex(index);
    } else {
      setEditingAnnotiationIndex(undefined);
    }
  }

  const handleAnnotationChange = (index, name, value) => {
    const newAnnotation = annotations[index];
    newAnnotation[name] = value;
    updateAnnotation(newAnnotation, index);
  }

  const onRemoveAnnotation = (index) => {
    removeAnnotation(index);
  }

  const onDownload = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(template, null, 4)
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    // link.download = updateExtension(imageName);
    link.download = 'data.json' // TODO: rename according to file name
    link.click();
  }

  return (
    <>
      <EditorSection>
        <Row justify="between">
          <Button
            onClick={() => navigate("/oneshot")}
            iconLeft={<TriangleBack />}>
            Back to menu
          </Button>
          <ButtonGroup>
            <StyledButton
              onClick={() => setShowAnnotationBoxes(!showAnnotationBoxes)}
              disabled={!template.annotations}>
                Annotations
            </StyledButton>
            <StyledButton onClick={() => setShowBoundingBoxes(!showBoundingBoxes)}>Visualise OCR</StyledButton>
            <StyledButton onClick={() => onDownload()}>Download</StyledButton>
          </ButtonGroup>
        </Row>
        <EditableTemplate
          displayImage={template.image}
          words={template.words}
          showBoundingBoxes={showBoundingBoxes}
          annotations={template.annotations}
          showAnnotationBoxes={showAnnotationBoxes}
          onDragStart={handleDragStart}
          onDragMove={handleDragMove}
          onDragEnd={handleDragEnd}
          isDragging={editingAnnotationIndex !== undefined}
          selectionBoxProperties={selectionBoxProperties}
          imageElementRef={imageElementRef}
          imageScale={imageScale}
          setImageScale={setImageScale}
          highlightedWords={highlightedWords}
          />
      </EditorSection>
      <EditorSidebar>
        <TemplateAnnotations
          annotations={annotations}
          addNewAnnotation={addNewAnnotation}
          startEditingAnnotation={startEditingAnnotation}
          handleAnnotationChange={handleAnnotationChange}
          onRemoveAnnotation={onRemoveAnnotation}
          editingIndex={editingAnnotationIndex}
          projectId={template.project_id}
          />
      </EditorSidebar>
    </>
  )
}

export default TemplateEditor;
