import { useParams } from "react-router-dom";
import ExtractViewer from "../../../components/oneshot/extract_viewer";

const OneShotExtractsShowScreen = () => {
  let { id } = useParams();

  return (
    <ExtractViewer id={id} />
  )
}

export default OneShotExtractsShowScreen;
