import { Button, ButtonGroup } from "@blueprism/ui-core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useProjects from "../../../hooks/use_projects";

import styled from "styled-components";
import { Trash } from "@blueprism/ui-icons";

import { b64toBlob } from "../../../services/file_helpers";

const StyledButton = styled(Button)`
  min-width: 2rem;
`;

const TemplateTableWrap = styled.div`
  > div:last-of-type {
    border-bottom: 1px solid #ccc;
  }
`

const TemplateTableRowWrap = styled.div`
  display: flex;
  border-top: 1px solid #ccc;
  padding: 0.8rem;
`

const TemplateRowThumbnail = styled.div`
  flex: 0 0 10rem;
  max-height: 10rem;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const TemplateRowDetails = styled.div`
  flex: 1;
`;

const TemplateRowDl = styled.dl`
  display: grid;
  grid-template-columns: 140px 1fr;
`;

const TemplateRowDd = styled.dd`
  font-weight: 600;
`;

const TemplateRowDt = styled.dt`
`;

const TemplateRowActions = styled.div`
  flex: 0 1;
`;

const TemplateTableRow = ({ extract, createTemplate, deleteExtract }) => {
  const [thumbnail, setThumbnail] = useState();
  const { projects } = useProjects();
  const navigate = useNavigate();

  const editExtract = () => {
    const newTemplate = extract.data.extractions[0].document;
    newTemplate.annotations = extract.data.extractions[0].annotations;
    newTemplate.project_id = projects[0].id;
    createTemplate(newTemplate)
    .then((templateId) => {
      navigate(`/oneshot/templates/${templateId}/edit`);
      deleteExtract(extract.id);
    })
  }

  useEffect(() => {
    b64toBlob(extract.data.extractions[0].document.image, "application/png")
      .then((parsedImage) => {
        const imageObject = new Image();
        imageObject.src = URL.createObjectURL(parsedImage)
        setThumbnail(imageObject);
      }, []);
  }, [extract.data.extractions[0].document.image]);

  return (
    <TemplateTableRowWrap key={extract.id}>
      <TemplateRowThumbnail>{ thumbnail && <img src={thumbnail.src} alt="Thumbnail" /> }</TemplateRowThumbnail>
      <TemplateRowDetails>
        <TemplateRowDl>
          <TemplateRowDd>ID</TemplateRowDd>
          <TemplateRowDt>{ extract.id }</TemplateRowDt>
          <TemplateRowDd>Annotations</TemplateRowDd>
          <TemplateRowDt>
            { extract.data.extractions[0].annotations?.length > 0 ? `${extract.data.extractions[0].annotations.length} annotations` : "None" }
          </TemplateRowDt>
          <TemplateRowDd>Words</TemplateRowDd>
          <TemplateRowDt>
            { extract.data.extractions[0].document.words?.length > 0 ? `${extract.data.extractions[0].document.words.length} words` : "0" }
          </TemplateRowDt>
        </TemplateRowDl>
      </TemplateRowDetails>
      <TemplateRowActions>
        <ButtonGroup>
          <StyledButton active onClick={() => navigate(`/oneshot/extracts/${extract.id}`)}>
            View
          </StyledButton>
          <StyledButton active onClick={() => editExtract(extract.id)}>
            Edit
          </StyledButton>
          <StyledButton onClick={() => deleteExtract(extract.id)}>
            <Trash size={12} />
          </StyledButton>
        </ButtonGroup>
      </TemplateRowActions>
    </TemplateTableRowWrap>
  )
}

const ExtractsTable = ({ extracts, createTemplate, deleteExtract }) => {
  return (
    <TemplateTableWrap>
      { extracts.map((extract) =>
        <TemplateTableRow
          key={`extract_${extract.id}`}
          extract={extract}
          createTemplate={createTemplate}
          deleteExtract={deleteExtract}
        />
      )}
    </TemplateTableWrap>
  );
}

export default ExtractsTable;
