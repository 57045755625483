import styled from "styled-components";
import Annotation from "./annotation";
import useProject from "../../../hooks/use_project";

const AnnotationsWrap = styled.div`
  display: sticky;
`;

const ExtractAnnotations = ({
  annotations = [],
  projectId
}) => {
  const { getFields } = useProject(projectId);
  const fields = getFields();

  return (
    <AnnotationsWrap>
      {annotations.map((annotation, index) =>
        <Annotation
          key={`extractAnnotation_${index}`}
          annotation={annotation}
          fields={fields} />
      )}
    </AnnotationsWrap>
  )
}

export default ExtractAnnotations;
