import styled from "styled-components";
import Rocket from "@blueprism/ui-icons/Rocket";

const FileProcessing = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background: rgba(255, 255, 255, 0.6);

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: 333;
    font-size: 1.6rem;
  }
`;

const StyledRocket = styled(Rocket)`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  animation-duration: 2s;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  margin-right: 1rem;
`

const ProcessingWidget = () => {
  return (
    <FileProcessing><StyledRocket size={48} /> <p>Processing</p></FileProcessing>
  )
}

export default ProcessingWidget;