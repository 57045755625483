import { Button, ButtonGroup } from "@blueprism/ui-core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Trash } from "@blueprism/ui-icons";

import { b64toBlob } from "../../../services/file_helpers";
// import ButtonLink from "../../button_link";

const StyledButton = styled(Button)`
  min-width: 2rem;
`;

const AnnotationButton = styled(Button)`
  min-width: 2rem;
  width: 2rem;
  padding: 0.6rem 0;
  margin: 0;
  text-align: center;
`;

const TemplateTableWrap = styled.div`
  > div:last-of-type {
    border-bottom: 1px solid #ccc;
  }
`

const TemplateTableRowWrap = styled.div`
  display: flex;
  border-top: 1px solid #ccc;
  padding: 0.8rem;
`

const TemplateRowThumbnail = styled.div`
  flex: 0 0 10rem;
  max-height: 10rem;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const TemplateRowDetails = styled.div`
  flex: 1;
`;

const TemplateRowDl = styled.dl`
  display: grid;
  grid-template-columns: 140px 1fr;
`;

const TemplateRowDd = styled.dd`
  font-weight: 600;
`;

const TemplateRowDt = styled.dt`
`;

const TemplateRowActions = styled.div`
  flex: 0 1;
`;

const TemplateTableRow = ({ template, deleteTemplate }) => {
  const [thumbnail, setThumbnail] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    b64toBlob(template.image, "application/png")
      .then((parsedImage) => {
        const imageObject = new Image();
        imageObject.src = URL.createObjectURL(parsedImage)
        setThumbnail(imageObject);
      }, []);
  }, [template.image]);

  return (
    <TemplateTableRowWrap key={template.id}>
      <TemplateRowThumbnail>{ thumbnail && <img src={thumbnail.src} alt="Thumbnail" /> }</TemplateRowThumbnail>
      <TemplateRowDetails>
        <TemplateRowDl>
          <TemplateRowDd>ID</TemplateRowDd>
          <TemplateRowDt>{ template.id }</TemplateRowDt>
          <TemplateRowDd>Annotations</TemplateRowDd>
          <TemplateRowDt>{ template.annotations?.length > 0 ? `${template.annotations.length} annotations` : "None" }</TemplateRowDt>
          <TemplateRowDd>Words</TemplateRowDd>
          <TemplateRowDt>{ template.words?.length > 0 ? `${template.words.length} words` : "0" }</TemplateRowDt>
        </TemplateRowDl>
      </TemplateRowDetails>
      <TemplateRowActions>
        <ButtonGroup>
          {/* <ButtonLink to={`/oneshot/templates/${template.id}/edit`}>
            Edit
          </ButtonLink> */}
          <StyledButton active onClick={() => navigate(`/oneshot/templates/${template.id}/edit`)}>
            Edit
          </StyledButton>
          <AnnotationButton onClick={() => deleteTemplate(template.id)}>
            <Trash size={12} />
          </AnnotationButton>
        </ButtonGroup>
      </TemplateRowActions>
    </TemplateTableRowWrap>
  )

}


const TemplatesTable = ({ templates, deleteTemplate }) => {
  return (
    <TemplateTableWrap>
      { templates.map((template) =>
        <TemplateTableRow key={`template_${template.id}`} template={template} deleteTemplate={deleteTemplate} />
      )}
    </TemplateTableWrap>
  );
}

export default TemplatesTable;
