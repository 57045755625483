import { useState } from "react";
import { Button, DialogModal, Row } from "@blueprism/ui-core";

import PdfUploader from "../../components/pdf_uploader";
import { pdfCompare } from "../../services/api/doccompare";
import ProcessingWidget from "../../components/processing_widget";
import useAuthKeyStore from "../../stores/auth_key";
import { b64toBlob, fileToDataUrl } from "../../services/file_helpers";


const DocCompareMainScreen = () => {
  const { authKey } = useAuthKeyStore();
  const [isProcessed, setIsProcessed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [pdfFileOne, setPdfFileOne] = useState(undefined);
  const [pdfFileTwo, setPdfFileTwo] = useState(undefined);
  const [pdfFileOneWithMatches, setPdfFileOneWithMatches] = useState(undefined);
  const [pdfFileTwoWithMatches, setPdfFileTwoWithMatches] = useState(undefined);
  const [pdfFileOneWithMismatches, setPdfFileOneWithMismatches] = useState(undefined);
  const [pdfFileTwoWithMismatches, setPdfFileTwoWithMismatches] = useState(undefined);
  const [showErrorModal, setShowErrorModal] = useState(false);
  

  const resetPage = () => {
    setIsProcessed(false);
    handleOnClear(setPdfFileOne);
    handleOnClear(setPdfFileTwo);
    handleOnClear(setPdfFileOneWithMatches);
    handleOnClear(setPdfFileTwoWithMatches);
    handleOnClear(setPdfFileOneWithMismatches);
    handleOnClear(setPdfFileTwoWithMismatches);
    // window.location.reload(false);
  }

  const handleFileChange = (file, setter) => {
    setter(file);
  }

  const handleOnClear = (setter) => {
    setter(undefined);

    // In my head, this should be && instead of ||. However, upon the change 
    // i.e. the `setter(undefined)` the file variable still remains populated
    if (pdfFileOne === undefined || pdfFileTwo === undefined) {
      setIsProcessed(false);
    }
  }

  const onCompare = (pdfFileOne, pdfFileTwo) => {
    setIsProcessing(true);
    Promise.all([fileToDataUrl(pdfFileOne), fileToDataUrl(pdfFileTwo)])
      .then(([dataUrlPdfOne, dataUrlPdfTwo]) => {
        pdfCompare(dataUrlPdfOne, dataUrlPdfTwo, authKey)
        .then(
          (res) => res.json(),
          (err) => setShowErrorModal(true)
        )
        .then(async (res) => {
          setPdfFileOne(await b64toBlob(res.data.pdf1));
          setPdfFileOneWithMismatches(await b64toBlob(res.data.pdf1));
          setPdfFileOneWithMatches(await b64toBlob(res.data.pdf1_detailed));
          setPdfFileTwo(await b64toBlob(res.data.pdf2));
          setPdfFileTwoWithMismatches(await b64toBlob(res.data.pdf2));
          setPdfFileTwoWithMatches(await b64toBlob(res.data.pdf2_detailed));
          setIsProcessed(true);
        })
        .catch((err) => console.error(err))
        .finally(() => setIsProcessing(false));
      })
      .catch((err) => console.error(err))
  }

  const onDownload = (name) => {
    const link = document.createElement('a');

    if (name === 'pdfFileOne' && pdfFileOne !== undefined) {
      const pdfObjectURLOne = URL.createObjectURL(pdfFileOne)
      link.href = pdfObjectURLOne;
      link.download = 'pdf1.pdf';
      link.click();
    }

    if (name === 'pdfFileTwo' && pdfFileTwo !== undefined) {
      const pdfObjectURLTwo = URL.createObjectURL(pdfFileTwo)
      link.href = pdfObjectURLTwo;
      link.download = 'pdf2.pdf';
      link.click();
    }
  }

  const onHighlight = () => {
    if (pdfFileOne === pdfFileOneWithMatches && pdfFileTwo === pdfFileTwoWithMatches) {
      handleFileChange(pdfFileOneWithMismatches, setPdfFileOne);
      handleFileChange(pdfFileTwoWithMismatches, setPdfFileTwo);
    } else {
      handleFileChange(pdfFileOneWithMatches, setPdfFileOne);
      handleFileChange(pdfFileTwoWithMatches, setPdfFileTwo);
    }
  }

  return (
    <>
      <Row justify="end">
        <Button
          active={pdfFileOne && pdfFileTwo}
          disabled={isProcessing || (!pdfFileOne || !pdfFileTwo)}
          onClick={() => onCompare(pdfFileOne, pdfFileTwo)}>
            Compare
        </Button>
        <Button
          disabled={!pdfFileOneWithMismatches || !pdfFileTwoWithMismatches}
          onClick={onHighlight}>
            Highlight
        </Button>
        <Button
          disabled={!pdfFileOne && !pdfFileTwo}
          onClick={resetPage}>
            Reset
        </Button>
      </Row>

      <Row>
        <PdfUploader
          file={pdfFileOne}
          handleOnClear={() => {
            handleOnClear(setPdfFileOne);
            handleOnClear(setPdfFileOneWithMatches);
            handleOnClear(setPdfFileOneWithMismatches);
          }}
          handleChange={(file) => handleFileChange(file, setPdfFileOne)}
          name="pdfFileOne"
          onDownload={onDownload}
          isProcessed={isProcessed} />
        <PdfUploader
          file={pdfFileTwo}
          handleOnClear={() => {
            handleOnClear(setPdfFileTwo);
            handleOnClear(setPdfFileTwoWithMatches);
            handleOnClear(setPdfFileTwoWithMismatches);
          }}
          handleChange={(file) => handleFileChange(file, setPdfFileTwo)}
          name="pdfFileTwo"
          onDownload={onDownload}
          isProcessed={isProcessed} />
      </Row>

      <DialogModal
        closeButton
        overlay
        visible={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        width="600px">
          <p>Unable to extract data from the given PDF. Please upload a different PDF and try again.</p>
      </DialogModal>

      { isProcessing && <ProcessingWidget /> }
    </>
  );
}

export default DocCompareMainScreen;
