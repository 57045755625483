import { Button, Row } from "@blueprism/ui-core";
import DialogModal from "@blueprism/ui-core/components/DialogModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useProjects from "../../../hooks/use_projects";
import useTemplates from "../../../hooks/use_templates";
import { docProcess } from "../../../services/api/oneshot";
import { fileToDataUrl } from "../../../services/file_helpers";
import useAuthKeyStore from "../../../stores/auth_key";
import OneShotPdfUploader from "../../oneshot_pdf_uploader";
import ProcessingOverlay from "../processing_overlay";

const FooterContent = ({ onClose, onSubmit }) => (
  <Row justify="between" padding="small">
    <Button onClick={onClose}>Cancel</Button>
    <Button onClick={onSubmit}>Process</Button>
  </Row>
);

const NewTemplateModal = ({
  visible,
  onClose
}) => {
  const navigate = useNavigate();
  const { projects } = useProjects();
  const { createTemplate } = useTemplates();
  const [file, setFile] = useState(undefined);
  const { authKey } = useAuthKeyStore();
  const [isProcessing, setIsProcessing] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [useTextract, setUseTextract] = useState(false);

  const toggleUseTextract = () => {
    setUseTextract(current => !current)
  }

  const handlePdfChange = (newFile) => {
    setFile(newFile);
  }

  const handlePdfClear = () => {
    setFile(undefined);
  }

  const handleModalSubmit = () => {
    setIsProcessing(true);
    fileToDataUrl(file)
      .then((processedField) => docProcess(0, processedField, pageNum, authKey, useTextract))
      .then((res) => res.json())
      .then((res) => {
        // For now, hardcoded to send just 1 PDF
        const newTemplate = res.data[0];
        newTemplate.project_id = projects[0].id;
        return createTemplate(newTemplate);
      })
      .then((templateId) => {
        navigate(`/oneshot/templates/${templateId}/edit`)
      })
      .catch((err) => setShowErrorModal(true))
      .finally(() => setIsProcessing(false));
  }

  return (
    <>
      <DialogModal
        closeButton
        overlay
        visible={visible}
        onClose={onClose}
        headerContent={<h3 style={{ marginBottom: "0px" }}>New Template</h3>}
        footerContent={<FooterContent onClose={onClose} onSubmit={handleModalSubmit} />}
        width="600px"
        >
        <p>Upload your PDF file here, and then click 'Process' to begin text extraction of the document.</p>
        <OneShotPdfUploader
          toggleUseTextract={toggleUseTextract}
          handleChange={handlePdfChange}
          handleOnClear={handlePdfClear}
          setPageNum={setPageNum}
          pageNum={pageNum}
          file={file}
          name="pdf_input_template"
          />
        { isProcessing && <ProcessingOverlay><p>Processing</p></ProcessingOverlay> }
      </DialogModal>

      <DialogModal
          closeButton
          overlay
          visible={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          width="600px">
            <p>Unable to extract data from the given PDF. Please upload a different PDF or try 
              the <b>Textract</b> option.</p>
      </DialogModal>
    </>
  )
}

export default NewTemplateModal;
