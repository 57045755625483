import OneShotExtractsShowScreen from "../../../screens/oneshot/extracts/show";
import LoggedInWrapper from "../../../components/logged_in_wrapper";
import LayoutOneShot from "../../../components/layout/oneshot";

const OneShotExtractsShowPage = () => {
  return (
    <LoggedInWrapper serviceName="OneShot" serviceId="oneshot">
      <LayoutOneShot>
        <OneShotExtractsShowScreen />
      </LayoutOneShot>
    </LoggedInWrapper>
  );
}

export default OneShotExtractsShowPage;
