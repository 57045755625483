import styled from "styled-components";
import LayoutHeader from "./parts/header";
import LayoutWrapper from "./parts/wrapper";
import Button from '@blueprism/ui-core/components/Button';
import { Row } from "@blueprism/ui-core";
import { Paragraph } from "@blueprism/ui-icons";
import React from "react";

const LayoutMain = styled.main`
  width: 100%;
  flex: 1;
  margin: 0 auto;
  padding: 0.8rem 0;
  background: ${(props) => props.theme.color.paper};

  @media (min-width: 1001px) {
    width: 1000px;
  } 
`;

const FeedbackButton = styled(Button)`
  color: #FFFFFF;
  background: transparent;
  // margin: 15px 0 0 0;
  padding: 0;
  // height: 20px;
`;

const Layout = ({ children, serviceName, feedbackRedirect=null, DropdownComponent=null }) => (
  <LayoutWrapper>
    <LayoutHeader>
      <Row gap='large'>
        <h1>{serviceName}</h1>
        { feedbackRedirect &&
          <FeedbackButton
            iconRight={<Paragraph />}
            onClick={() => window.location.replace(feedbackRedirect)}
          >
            Feedback
          </FeedbackButton>
        }
      </Row>
      { DropdownComponent && <DropdownComponent />}
    </LayoutHeader>
    <LayoutMain>
      { children }
    </LayoutMain>
  </LayoutWrapper>
);

export default Layout;
