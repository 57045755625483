import { postRequest } from "./base";

export const docProcess = (pdfIndex, pdf, pageNum, authKey, useTextract=false) =>
  postRequest({
    url: `${process.env.REACT_APP_API_BASE_URL}/oneshot/docprocess?use_textract=${useTextract}`,
    authKey: authKey,
    data: {
      payload: {
        pdf_files: {
          [pdfIndex]: {
            serialized_pdf: pdf,
            page_number: pageNum
          }
        }
      }
    },
    skipParse: true
  })

export const postExtract = (data, authKey) =>
  postRequest({
    url: `${process.env.REACT_APP_API_BASE_URL}/oneshot/extract`,
    authKey: authKey,
    data: {
      payload: data
    },
    skipParse: true
  })

export const splitPdf = (pdf, authKey) =>
  postRequest({
    url: `${process.env.REACT_APP_API_BASE_URL}/oneshot/split_pdf`,
    authKey: authKey,
    data: {
      pdf: pdf
    },
    skipParse: true
  })
