import styled from "styled-components";

const LayoutLine = styled.div`
  position: absolute;
  background: rgba(255, 10, 10, 0.2);
  border: 1px solid rgba(255, 10, 10, 0.6);
  
  &:hover {
    > p {
      display: block;
    }
  }
`;

const DrawLayout = ({ imageLayout, imageScale }) => {
  return (
    <>
      { imageLayout.map((point, index) => 
        <LayoutLine
          key={`line_${index}`}
          style={{
            top: `${point[0][1] * imageScale}px`,
            left: `${point[0][0] * imageScale}px`,
            width: `${(point[1][0] - point[0][0]) * imageScale}px`,
            height: `${(point[1][1] - point[0][1]) * imageScale}px`,
          }}/>
      )}
    </>
  )
}

export default DrawLayout;
