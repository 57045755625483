import { postRequest } from "./base";

export const pdfCompare = (pdfFileOne, pdfFileTwo, authKey) =>
  postRequest({
    url: `${process.env.REACT_APP_API_BASE_URL}/doccompare/compare`,
    authKey: authKey,
    data: {
      key: authKey,
      payload: {
        pdf1: pdfFileOne,
        pdf2: pdfFileTwo
      }
    },
    skipParse: true
  })
