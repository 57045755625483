import styled from "styled-components";

const ProcessingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);

  p {
    font-size: 1.4rem;
    text-align: center;
  }
`;

export default ProcessingOverlay;
