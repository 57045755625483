import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../database/oneshot";
import { v4 as uuidv4 } from "uuid";

const useTemplates = () => {
  const templates = useLiveQuery(() => db.templates.toArray()) || [];

  const createTemplate = async (data) =>
    db.templates.add({
      id: uuidv4(),
      ...data
    });

  const deleteTemplate = async (id) => db.templates.delete(id);

  return {
    templates,
    createTemplate,
    deleteTemplate
  };
}

export default useTemplates;
