import { useState, useEffect } from "react";
import styled from "styled-components";
import { b64toBlob } from "../../../services/file_helpers";

const EditableTemplateWrap = styled.div`
  position: relative;
  margin-top: 0.6rem;
  background: ${(props) => props.theme.color.paper};
  border-radius: 0.8rem;
  overflow: hidden;
`;

const TemplateWordBox = styled.div`
  position: absolute;
  background: ${(props) => props.theme.color.focus}33;
  border: 2px solid ${(props) => props.theme.color.focus}66;
  border-radius: 4px;
  
  &:hover {
    > p {
      display: block;
    }
  }
`;

const AnnotationWordBox = styled.div`
  position: absolute;
  background: ${(props) => props.theme.color.action}33;
  border: 2px solid ${(props) => props.theme.color.action}66;
  border-radius: 4px;
  
  &:hover {
    > p {
      display: block;
    }
  }
`;

const HighlightedWordBox = styled.div`
  position: absolute;
  background: ${(props) => props.theme.color.lavender}33;
  border: 2px solid ${(props) => props.theme.color.lavender}66;
  border-radius: 4px;
`;

const TemplateWord = styled.p`
  display: none;
  background: rgba(230, 10, 10, 0.9);
  position: absolute;
  top: -32px;
  left: 0px;
  height: 18px;
  padding: 1px 2px;
  font-size: 12px;
  color: #fff;
`;

const SelectionBox = styled.div`
  display: block;
  position: absolute;
  background: repeating-linear-gradient(
    45deg,
    ${(props) => props.theme.color.lavender}22,
    ${(props) => props.theme.color.lavender}22 10px,
    ${(props) => props.theme.color.lavender}11 10px,
    ${(props) => props.theme.color.lavender}11 20px
  );
  border: 1px solid ${(props) => props.theme.color.lavender}88;
  border-radius: 4px;
`;

const ImagePreview = styled.img`
  width: 100%;
`;

const EditableTemplate = ({
  displayImage,
  words,
  showBoundingBoxes=false,
  annotations,
  showAnnotationBoxes=false,
  onDragStart,
  onDragMove,
  onDragEnd,
  isDragging,
  selectionBoxProperties,
  imageElementRef,
  imageScale,
  setImageScale,
  highlightedWords
}) => {
  const [image, setImage] = useState(undefined);
  const [imageOriginalDimensions, setImageOriginalDimensions] = useState({});

  useEffect(() => {
    b64toBlob(displayImage, "application/png")
      .then((parsedImage) => {
        // An image object allows us to extract details about the image
        const imageObject = new Image();
        imageObject.onload = function() {
          setImageOriginalDimensions({
            width: this.width,
            height: this.height
          });

          setImageScale(imageElementRef.current.clientWidth / this.width);
        }
        imageObject.src = URL.createObjectURL(parsedImage)
        setImage(imageObject)
      });
  }, []);

  useEffect(() => {
    if (imageElementRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        setImageScale(entries[0].contentRect.width / imageOriginalDimensions.width);
      });
      
      resizeObserver.observe(imageElementRef.current);
    }
  }, [imageOriginalDimensions.width]);

  return (
    <EditableTemplateWrap onMouseDown={onDragStart} onMouseUp={onDragEnd} onMouseMove={onDragMove}>
      { image && <ImagePreview ref={imageElementRef} src={image.src} draggable={false} />}
      { showBoundingBoxes && words.map((word, index) =>
        <TemplateWordBox
          key={`word_${index}`}
          style={{
            top: `${word.bbox.ymin * imageScale}px`,
            left: `${word.bbox.xmin * imageScale}px`,
            width: `${word.bbox.width * imageScale}px`,
            height: `${word.bbox.height * imageScale}px`,
          }}>
          <TemplateWord>{ word.text }</TemplateWord>
        </TemplateWordBox>
      )}
      { showAnnotationBoxes && annotations.map((annotation, index) =>
        <AnnotationWordBox
          key={`annotation_${index}`}
          style={{
            top: `${annotation.bbox.ymin * imageScale}px`,
            left: `${annotation.bbox.xmin * imageScale}px`,
            width: `${annotation.bbox.width * imageScale}px`,
            height: `${annotation.bbox.height * imageScale}px`,
          }}>
          <TemplateWord>{ annotation.bounded_text }</TemplateWord>
        </AnnotationWordBox>
      )}
      { isDragging && highlightedWords.map((word, index) =>
        <HighlightedWordBox
          key={`highlighed_word_${index}`}
          style={{
            top: `${word.bbox.ymin * imageScale}px`,
            left: `${word.bbox.xmin * imageScale}px`,
            width: `${word.bbox.width * imageScale}px`,
            height: `${word.bbox.height * imageScale}px`,
          }} />
      )}
      { isDragging && imageElementRef.current &&
        <SelectionBox
          style={{
            width: `${selectionBoxProperties.width}px`,
            height: `${selectionBoxProperties.height}px`,
            left: `${selectionBoxProperties.x}px`,
            top: `${selectionBoxProperties.y}px`,
          }} />
      }
    </EditableTemplateWrap>
  )
}

export default EditableTemplate;
