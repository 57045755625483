import Layout from "../../components/layout";
import LoggedInWrapper from "../../components/logged_in_wrapper";
import OneShotSettingsDropdown from "../../components/oneshot/settings_dropdown";
import OneShotTemplatesIndexScreen from "../../screens/oneshot/templates";

const OneShotIndexPage = () => {
  return (
    <LoggedInWrapper serviceName="OneShot" serviceId="oneshot">
      <Layout serviceName="OneShot" feedbackRedirect="https://blueprism.az1.qualtrics.com/jfe/form/SV_3C6a1jtxUh7tZhY" DropdownComponent={OneShotSettingsDropdown}>
        <OneShotTemplatesIndexScreen />
      </Layout>
    </LoggedInWrapper>
  );
}

export default OneShotIndexPage;
