import { useState } from 'react';
import { Document, Page } from 'react-pdf';

import { FilePicker } from "@blueprism/ui-pattern-file-picker";
import { Row, Button, FormField } from '@blueprism/ui-core';

import styled from "styled-components";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

const PdfUploaderWrapper = styled.div`
  padding: 0 0.6rem;
  width: 100%;

  & + div {
    border-left: 1px solid ${(props) => props.theme.color.structure}
  }
`;

const PdfPlaceholder = styled.label`
  height: 7rem;
  width: 100%;
  border-radius: 0.8rem;
  margin: 0.8rem 0;
  background: ${(props) => props.theme.color.highlight};
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 1.2rem;
  }
`

const PdfUploader = ({ 
  handleChange,
  handleOnClear,
  file,
  name,
  onDownload,
  isProcessed
}) => {
  const [errors, setErrors] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNum(1);
  }

  const changePageNext = () => {
    setPageNum(pageNum + 1);
  }

  const changePagePrev = () => {
    setPageNum(pageNum - 1);
  }

  const checkFileSize = (file) => {
    if (file.size > 15050000) {
      setErrors([...errors, 'File must be 15MB or less. Choose a smaller file']);
    } else {
      setErrors([]);
      handleChange(file)
    }
  }

  const onChange = (e) => {
    const fileToCheck = e.target.files[0];

    checkFileSize(fileToCheck)
  }

  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const onDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();

    let file = undefined;

    if (e.dataTransfer.items) {
      if (e.dataTransfer.items[0].kind === "file") {
        file = e.dataTransfer.items[0].getAsFile();
      }
    } else if (e.dataTransfer.files) {
      file = e.dataTransfer.files[0];
    }
  
    if (file) {
      checkFileSize(file);
    }
  }

  return (
    <PdfUploaderWrapper>
      <FormField
        error={errors.length > 0}
        errorText={errors}
        htmlFor="FilePickerWithErrorHandling">
        <Row gap="xxs">
          <FilePicker
            id={name}
            accept=".pdf, .PDF"
            files={file ? [file] : null}
            chooseButtonLabel="Upload PDF"
            clearButtonLabel="Clear"
            onChange={onChange}
            onClear={handleOnClear}
          />
          { onDownload && 
            <Button
              disabled={!isProcessed || !file}
              onClick={() => onDownload(name)}>
                Download
            </Button>}
        </Row>
      </FormField>

      { !file &&
        <PdfPlaceholder
          htmlFor={name}
          onDrop={onDrop}
          onDragOver={onDragOver}>
            <p>Click or drag a PDF here</p>
        </PdfPlaceholder>}

      { file &&
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page width={480} pageNumber={pageNum} />
        </Document>
      }

      { file && 
        <Row justify="around">
          <Button disabled={pageNum === 1} onClick={changePagePrev}> Previous</Button>
          <p>Page: {pageNum}/{numPages}</p>
          <Button disabled={pageNum >= numPages} onClick={changePageNext}> Next</Button>
        </Row>
      }
    </PdfUploaderWrapper>
  );
}

export default PdfUploader;
