export function getRequest({ url, skipParse=false, authKey="", contentType="application/json" }) {
  return fetch(url, {
    headers: {
      "x-api-key": authKey,
      "Content-Type": contentType
    }
  }).then(res => skipParse ? res : res.json());
}
  
export function postRequest({ url, data, skipParse=false, skipStringify=false, authKey="", contentType="application/json" }) {
  let headers = {"x-api-key": authKey};
  console.log("data", data);

  if(!skipStringify) {
    headers["Content-Type"] = contentType
  }

  return fetch(url, {
    method: "POST",
    headers,
    body: skipStringify ? data : JSON.stringify(data),
    timeout: 60000
  }).then(res => skipParse ? res : res.json());
}

export function patchRequest({ url, data, skipParse=false, skipStringify=false, contentType="application/json" }) {
  return fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": contentType
    },
    body: skipStringify ? data : JSON.stringify(data),
  }).then(res => skipParse ? res : res.json());
}

export function deleteRequest({ url, contentType="application/json" }) {
  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": contentType
    }
  });
}
