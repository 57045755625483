import styled from "styled-components";
import LayoutHeader from "./parts/header";
import LayoutWrapper from "./parts/wrapper";

const OneShotHeader = styled(LayoutHeader)`
  flex: 0 0 46px;
`;

const LayoutMain = styled.main`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex: 1 0 calc(100vh - 46px);
  background: ${(props) => props.theme.color.paper};
`;

const LayoutOneShot = ({ children }) => (
  <LayoutWrapper>
    <OneShotHeader>
      <h1>OneShot</h1>
    </OneShotHeader>
    <LayoutMain>
      { children }
    </LayoutMain>
  </LayoutWrapper>
);

export default LayoutOneShot;
