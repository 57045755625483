import { useMemo } from "react";
import styled from "styled-components";
import Button from "@blueprism/ui-core/components/Button";
import FormField from "@blueprism/ui-core/components/FormField";
import InputField from "@blueprism/ui-core/components/InputField";
import Row from "@blueprism/ui-core/layout/Row";
import { BoundingBox, Pencil, Trash } from "@blueprism/ui-icons";
import { Option, Select } from "@blueprism/ui-core";

const StyledAnnotation = styled(Row)`
  margin-bottom: 0.8rem;
`;

const AnnotationInputWrap = styled.div`
  flex: 3;
  margin: 0 0.4rem;
`;

const AnnotationSelectWrap = styled.div`
  flex: 1;
  margin: 0 0.4rem;
`;

const AnnotationButtonWrap = styled.div`
  flex: 0 0 2rem;
  display: flex;
  align-items: end;
`;

const AnnotationButton = styled(Button)`
  min-width: 2rem;
  width: 2rem;
  padding: 0.6rem 0;
  margin: 0;
  text-align: center;
`;

const AnnotationField = styled.div`
  background: ${(props) => props.theme.color.highlight};
  display: flex;
  p {
    flex: 1;
  }
`;

const AnnotationSelect = styled.div`
  select {
    padding: 7px var(--s-2) !important;
  }
`

const Annotation = ({
  annotation,
  onEditClick,
  onRemove,
  index,
  onFieldEditClick,
  onRowChange,
  fields,
  beingEdited=false
}) => {
  const fieldName = useMemo(() => {
    return fields.find((field) => field.field_id === annotation.field_id)?.field_name || "";
  }, [fields, annotation.field_id]);

  const rowNumbers = useMemo(() =>
    Array.from(Array(12).keys()), []);

  return (
    <StyledAnnotation>
      <AnnotationInputWrap>
        <FormField
          htmlFor={`annotation_${annotation.annotation_id}_field`}
          label="Field">
          <AnnotationField>
          <InputField
            id={`annotation_${annotation.annotation_id}_field`}
            name={`annotation_${annotation.annotation_id}_field`}
            value={fieldName}
            disabled />
            <AnnotationButton
              onClick={onFieldEditClick}>
              <Pencil size={12} />
            </AnnotationButton>
          </AnnotationField>
        </FormField>
      </AnnotationInputWrap>
      <AnnotationInputWrap>
        <FormField
          htmlFor={`annotation_${annotation.annotation_id}_bounded_text`}
          label="Text">
          <InputField
            id="bounded_text"
            name="bounded_text"
            value={annotation.bounded_text}
            disabled />
        </FormField>
      </AnnotationInputWrap>
      <AnnotationButtonWrap>
        <AnnotationButton
          onClick={() => onEditClick(index)}
          active={beingEdited}>
          <BoundingBox size={12} />
        </AnnotationButton>
      </AnnotationButtonWrap>
      <AnnotationSelectWrap>
        <FormField
          htmlFor={`annotation_${annotation.annotation_id}_row_number`}
          label="Row">
          <AnnotationSelect>
            <Select
              id="row_number"
              name="row_number"
              value={annotation.row_number}
              onChange={onRowChange}>
              <Option value={-1}> </Option>
              { rowNumbers.map((number) =>
                <Option key={`annotation_${annotation.annotation_id}_${number}`} value={number}>{ number + 1 }</Option>
              )}
            </Select>
          </AnnotationSelect>
        </FormField>
      </AnnotationSelectWrap>
      <AnnotationButtonWrap>
        <AnnotationButton onClick={() => onRemove(index)}>
          <Trash size={12} />
        </AnnotationButton>
      </AnnotationButtonWrap>
    </StyledAnnotation>
  );
}

export default Annotation;
