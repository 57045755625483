import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../database/oneshot";
import { v4 as uuidv4 } from 'uuid';

const useExtracts = () => {
  const extracts = useLiveQuery(() => db.extracts.toArray()) || [];

  const createExtract = async (data) =>
    db.extracts.add({
      id: uuidv4(),
      template_id: data.data.extractions[0].template_id,
      ...data
    });

  const deleteExtract = async (id) => db.extracts.delete(id);

  return {
    extracts,
    createExtract,
    deleteExtract,
  };
}

export default useExtracts;
