import { useState } from "react";
import styled from "styled-components";

import { FormField, InputField } from "@blueprism/ui-core";
import Button from "@blueprism/ui-core/components/Button";
import DialogModal from "@blueprism/ui-core/components/DialogModal";
import Row from "@blueprism/ui-core/layout/Row";
import { Plus, Trash } from "@blueprism/ui-icons";

const FieldFormField = styled(FormField)`
  flex: 1;
`;

const FieldRow = styled.div`
  display: flex;
  border-top: 1px solid #ccc;

  &:last-of-type {
    border-bottom: 1px solid #ccc;
  }

  p {
    flex: 1;
  }

  button {
    flex: 0 0 40px;
  }
`;

const FooterContent = ({ onClose }) => (
  <Row justify="between" padding="small">
    <Button onClick={onClose}>Cancel</Button>
  </Row>
);

const Field = ({ field, onFieldSelect, onRemove, selectedFieldId, hasDependantAnnotation }) => {
  return (
    <FieldRow>
      <p>{field.field_name}</p>
      { selectedFieldId === field.field_id ?
        <Button disabled active>Selected</Button>
        :
        <Button onClick={() => onFieldSelect(field.field_id)}>Select</Button>
      }
      <Button 
        disabled={hasDependantAnnotation(field.field_id)}
        onClick={() => onRemove(field.field_id)}
      >
        <Trash size={12} />
      </Button>
    </FieldRow>
  )
}

const NewFieldFormRow = styled(Row)`
  align-items: end;
`;

const NewFieldFormButton = styled(Button)`
  margin: 0;
`;

const NewFieldForm = ({ handleSubmit }) => {
    const [fieldName, setFieldName] = useState("");

    return (
        <>
            <h3>New Field</h3>
            <NewFieldFormRow>
                <FieldFormField
                    htmlForm="field_name"
                    label="Name">
                    <InputField
                        id="field_name"
                        name="field_name"
                        value={fieldName}
                        onChange={(e) => setFieldName(e.target.value)} />
                </FieldFormField>
                <NewFieldFormButton
                    onClick={() => handleSubmit(fieldName)}
                    iconLeft={<Plus size={12} />}>
                    Add
                </NewFieldFormButton>
            </NewFieldFormRow>
        </>
    )
}

const FieldsModal = ({
    visible,
    onClose,
    onFieldChange,
    fields,
    addField,
    removeField,
    fieldsLoaded,
    selectedFieldId,
    annotations
}) => {
    const handleFieldSelect = (field_id) => {
        onFieldChange(field_id);
        onClose();
    }

    const onRemove = (field_id) => {
        removeField(field_id);
    }

    const hasDependantAnnotation = (field_id) => {
        return annotations.filter((item) => item.field_id === field_id).length > 0;
    }

    return (
        <DialogModal
            closeButton
            overlay
            visible={visible}
            onClose={onClose}
            headerContent={<h3 style={{ marginBottom: "0px" }}>Fields</h3>}
            footerContent={<FooterContent onClose={onClose} />}
            width="600px"
        >
            {fieldsLoaded &&
                fields.map((field) =>
                    <Field
                        key={`field_${field.field_id}`}
                        field={field}
                        onFieldSelect={handleFieldSelect}
                        onRemove={onRemove}
                        hasDependantAnnotation={hasDependantAnnotation}
                        selectedFieldId={selectedFieldId} />
                )
            }
            <NewFieldForm handleSubmit={addField} />
        </DialogModal>
    )
}

export default FieldsModal;
