import LoginScreen from "../screens/login";
import useAuthKeyStore from "../stores/auth_key";

const LoggedInWrapper = ({ children, serviceId, serviceName }) => {
  const { loggedIn } = useAuthKeyStore();

  if (loggedIn) {
    return children;
  } else {
    return <LoginScreen serviceId={serviceId} serviceName={serviceName} />
  }
}

export default LoggedInWrapper;
