import styled from "styled-components";

const LayoutWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.color.highlight};
`;

export default LayoutWrapper;
